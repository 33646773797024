import { memo, useEffect, useState, useCallback } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { PaginationControl } from 'react-bootstrap-pagination-control'
import NonBiddingKeywordsSkeleton from './skeleton/non_bidding_keywords_skeleton'
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Freeze, Sort, Filter } from '@syncfusion/ej2-react-grids'
import moment from 'moment-timezone'

moment.tz.setDefault("America/New_York")

const SearchImpressionShareReport = (props) => {
	let gridInstance;
	const {startDate, endDate, account, fetchAccountData } = props
	const [page, setPage] = useState(1)
	const [isImprShareDataLoading, setIsImprShareDataLoading] = useState(true)
	const [totalRows, setTotalRows] = useState(0)
	const [sortBy, setSortBy] = useState(null)
	const [sortDirection, setSortDirection] = useState(null)
	const [gridKey, setGridKey] = useState(Date.now())
	const [accountData, setAccountData] = useState({'result': [], count: 0})
	const filterSettings: any = { 
		type: 'Menu',
		operators: {
			stringOperator: [
				{ value: 'startswith', text: 'Starts With' },
				{ value: 'endswith', text: 'Ends With' },
				{ value: 'equal', text: 'Equal' },
		 		{ value: 'notequal', text: 'Not Equal' },
		 		{ value: 'contains', text: 'Contains' },
		 		{ value: 'doesnotcontain', text: 'Does Not Contain' },
			],
			numberOperator: [
				{ value: 'equal', text: 'Equal' },
		 		{ value: 'notequal', text: 'Not Equal' },
		 		{ value: 'greaterthan', text: 'Greater Than' },
		 		{ value: 'greaterthanorequal', text: 'Greater Than Or Equal' },
		 		{ value: 'lessthan', text: 'Less Than' },
		 		{ value: 'lessthanorequal', text: 'Less Than Or Equal' },
			],
		}
	}
	const [filterColumns, setFilterColumns] = useState([])

	const getAccountData = useCallback(() => {
		const account_id = account.id
		const sort_by = sortBy
		const sort_direction = sortDirection
		const filters = filterColumns
		const from_date = moment(startDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
		const to_date = moment(endDate, 'MM/DD/YYYY').format('YYYY-MM-DD')

		fetchAccountData({ page, account_id, sort_by, sort_direction, filters, from_date, to_date }).then(response => {
			setIsImprShareDataLoading(false)
			
			if(response.data.success) {
				setTotalRows(response.data.account_report.pagination.total_count)
				setAccountData(previousAccountData => {
					return {...previousAccountData, 'result': response.data.account_report.data, 'count': response.data.account_report.pagination.total_count}
				})
				setGridKey(Date.now())
			}
		}).catch(err => setIsImprShareDataLoading(false))
	}, [fetchAccountData, page, sortBy, sortDirection, filterColumns, account, startDate, endDate])

	useEffect(() => {
		if(account) {
			getAccountData()
		}
	}, [account, getAccountData])

	useEffect(() => {
		if(gridInstance) {
			gridInstance.dataSource = accountData
		}
	}, [gridInstance, accountData, gridKey])

	const dataStateChange = (args) => {
		if(args.action.requestType !== 'stringfilterrequest') {
			if(gridInstance) {
				gridInstance.showSpinner()
			}
		}
		if(args.action.requestType === 'sorting') {
			if(args.action.columnName && args.action.direction) {
				setSortBy(args.action.columnName)
				setSortDirection(args.action.direction)
			} else {
				setSortBy(null)
				setSortDirection(null)
			}

			setPage(1)

			return
		}

		if(args.action.requestType === 'filtering') {

			if(args.action.action === 'filter') {
				let updatedFilters = filterColumns.map(item => {
					
					if(item.field === args.action.currentFilterObject.field) {
						return { ...item, field: args.action.currentFilterObject.field, operator: args.action.currentFilterObject.operator, value: args.action.currentFilterObject.value }
					}

					return item
				})

				let filteredFilterColumns = updatedFilters.filter(item => item.field === args.action.currentFilterObject.field)
				if(filteredFilterColumns.length === 0) {
					updatedFilters.push({field: args.action.currentFilterObject.field, operator: args.action.currentFilterObject.operator, value: args.action.currentFilterObject.value})
				}

				if(updatedFilters.length > 0) {
					setFilterColumns(updatedFilters)
				} else {
					setFilterColumns(result => [...result, {field: args.action.currentFilterObject.field, operator: args.action.currentFilterObject.operator, value: args.action.currentFilterObject.value}])
				}
			}

			if(args.action.action === 'clearFilter') {
				const indexToRemove = filterColumns.findIndex(item => item.field === args.action.currentFilterObject.properties.field)
				let updatedFilters = filterColumns.filter((item, index) => index !== indexToRemove)
				setFilterColumns(updatedFilters)
			}

			setPage(1)

			return
		}
	}

	const showClickShare = (item) => {
		if(item.search_click_share > 0 && item.search_click_share < 10) {
			return '< 10%'
		}

		if(item.search_click_share > 90) {
			return '> 90%'
		}

		return Number(item.search_click_share/100).toLocaleString('en-US', {'style': 'percent', 'minimumFractionDigits': 2})
	}

	const showSearchImprShare = (item) => {
		if(item.search_impression_share > 0 && item.search_impression_share < 10) {
			return '< 10%'
		}

		if(item.search_impression_share > 90) {
			return '> 90%'
		}

		return Number(item.search_impression_share/100).toLocaleString('en-US', {'style': 'percent', 'minimumFractionDigits': 2})
	}

	const showSearchExactMatchImprShare = (item) => {
		if(item.search_exact_match_impression_share > 0 && item.search_exact_match_impression_share < 10) {
			return '< 10%'
		}

		if(item.search_exact_match_impression_share > 90) {
			return '> 90%'
		}

		return Number(item.search_exact_match_impression_share/100).toLocaleString('en-US', {'style': 'percent', 'minimumFractionDigits': 2})
	}

	const showSearchRankLostImprShare = (item) => {
		if(item.search_rank_lost_impression_share > 0 && item.search_rank_lost_impression_share < 10) {
			return '< 10%'
		}

		if(item.search_rank_lost_impression_share > 90) {
			return '> 90%'
		}

		return Number(item.search_rank_lost_impression_share/100).toLocaleString('en-US', {'style': 'percent', 'minimumFractionDigits': 2})
	}

	const showSearchBudgetLostImprShare = (item) => {
		if(item.search_budget_lost_impression_share > 0 && item.search_budget_lost_impression_share < 10) {
			return '< 10%'
		}

		if(item.search_budget_lost_impression_share > 90) {
			return '> 90%'
		}

		return Number(item.search_budget_lost_impression_share/100).toLocaleString('en-US', {'style': 'percent', 'minimumFractionDigits': 2})
	}

	const showSearchRankLostTopImprShare = (item) => {
		if(item.search_rank_lost_top_impression_share > 0 && item.search_rank_lost_top_impression_share < 10) {
			return '< 10%'
		}

		if(item.search_rank_lost_top_impression_share > 90) {
			return '> 90%'
		}

		return Number(item.search_rank_lost_top_impression_share/100).toLocaleString('en-US', {'style': 'percent', 'minimumFractionDigits': 2})
	}

	const showSearchBudgetLostTopImprShare = (item) => {
		if(item.search_budget_lost_top_impression_share > 0 && item.search_budget_lost_top_impression_share < 10) {
			return '< 10%'
		}

		if(item.search_budget_lost_top_impression_share > 90) {
			return '> 90%'
		}

		return Number(item.search_budget_lost_top_impression_share/100).toLocaleString('en-US', {'style': 'percent', 'minimumFractionDigits': 2})
	}

	const showSearchTopImprShare = (item) => {
		if(item.search_top_impression_share > 0 && item.search_top_impression_share < 10) {
			return '< 10%'
		}

		if(item.search_top_impression_share > 90) {
			return '> 90%'
		}

		return Number(item.search_top_impression_share/100).toLocaleString('en-US', {'style': 'percent', 'minimumFractionDigits': 2})
	}

	const showTopImprRatio = (item) => {
		if(item.top_impression_percentage > 0 && item.top_impression_percentage < 10) {
			return '< 10%'
		}

		if(item.top_impression_percentage > 90) {
			return '> 90%'
		}

		return Number(item.top_impression_percentage/100).toLocaleString('en-US', {'style': 'percent', 'minimumFractionDigits': 2})
	}

	const showAbsTopImprRatio = (item) => {
		if(item.absolute_top_impression_percentage > 0 && item.absolute_top_impression_percentage < 10) {
			return '< 10%'
		}

		if(item.absolute_top_impression_percentage > 90) {
			return '> 90%'
		}

		return Number(item.absolute_top_impression_percentage/100).toLocaleString('en-US', {'style': 'percent', 'minimumFractionDigits': 2})
	}

	return (
		<>
			{!isImprShareDataLoading &&
				<>
					<Row className="mb-4">
						<Col md={12}>
							<h4>Search Impression Share</h4>
							<GridComponent height={500} ref={grid => gridInstance = grid}  allowFiltering={true} allowSorting={true} frozenColumns={1} loadingIndicator={{ indicatorType: 'Shimmer' }} filterSettings={filterSettings} dataStateChange={dataStateChange}>
								<ColumnsDirective>
									<ColumnDirective allowFiltering={false} field='date' width='200' headerText='Day' />
									<ColumnDirective field='search_click_share' width='200' headerText='Click Share' template={showClickShare} />
									<ColumnDirective field='search_impression_share' width='200' headerText='Search Impr. Share' template={showSearchImprShare} />
									<ColumnDirective field='search_exact_match_impression_share' width='250' headerText='Search Exact Match Impr. Share' template={showSearchExactMatchImprShare} />
									<ColumnDirective field='search_rank_lost_impression_share' width='250' headerText='Search Lost Impr. Share (Rank)' template={showSearchRankLostImprShare} />
									<ColumnDirective field='search_budget_lost_impression_share' width='260' headerText='Search Lost Impr. Share (Budget)' template={showSearchBudgetLostImprShare} />
									<ColumnDirective field='search_rank_lost_top_impression_share' width='270' headerText='Search Lost Top Impr. Share (Rank)' template={showSearchRankLostTopImprShare} />
									<ColumnDirective field='search_budget_lost_top_impression_share' width='280' headerText='Search Lost Top Impr. Share (Budget)' template={showSearchBudgetLostTopImprShare} />
									<ColumnDirective field='search_top_impression_share' width='250' headerText='Search Top Impr. Share' template={showSearchTopImprShare} />
									<ColumnDirective field='top_impression_percentage' width='150' headerText='Impr. (Top) %' template={showTopImprRatio} />
									<ColumnDirective field='absolute_top_impression_percentage' width='200' headerText='Impr. (Abs Top) %' template={showAbsTopImprRatio} />

								</ColumnsDirective>
								<Inject services={[Freeze, Sort, Filter]}/>
							</GridComponent>
						</Col>
					</Row>

					<Row>
						<Col md={12}>
							<div className="text-center">
								<PaginationControl page={page} total={totalRows} changePage={(page) => {setPage(page); gridInstance.showSpinner();}} limit={100} ellipsis={1} />
							</div>
						</Col>
					</Row>
				</>


			}

			{isImprShareDataLoading &&
				<NonBiddingKeywordsSkeleton />
			}
		</>
	)

}

export default memo(SearchImpressionShareReport)