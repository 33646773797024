import DashboardService from 'services/dashboardService'

const getDashboardData = (params) => async(dispatch) => {
	try {
      
      const response = await DashboardService.getDashboardData(params)

      if(response.data.success) {
         dispatch({
            type: 'UPDATE_DASHBOARD_DATA',
            payload: response.data,
         })
      }
      return response

   } catch(err) {
      console.log(err)
   }  
}

const getNonBiddingKeywords = (params) => async(dispatch) => {
   try {
      
      const response = await DashboardService.getNonBiddingKeywords(params)

      if(response.data.success) {
         dispatch({
            type: 'UPDATE_NON_BIDDING_KEYWORDS',
            payload: response.data,
         })
      }
      return response

   } catch(err) {
      console.log(err)
   }  
}

const getAccountData = (params) => async(dispatch) => {
   try {
      
      const response = await DashboardService.getAccountData(params)
      return response
   } catch(err) {
      console.log(err)
   }  
}

export {
	getDashboardData, getNonBiddingKeywords, getAccountData
}
