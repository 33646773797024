import { connect } from 'react-redux'
import Dashboard from 'pages/dashboard'
import { getDashboardData, getNonBiddingKeywords, getAccountData } from 'actions'

const mapStateToProps = (state) => {
   return {
      default_account: state.reducer.default_account,
      ...state.dashboardReducer,
   };
};

const mapActionsToProps = { getDashboardData, getNonBiddingKeywords, getAccountData }

export default connect(mapStateToProps, mapActionsToProps)(Dashboard)