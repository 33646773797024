import { memo, useEffect, useState, useCallback } from 'react'
import Layout from 'layout'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import { PaginationControl } from 'react-bootstrap-pagination-control'
import DashboardSkeleton from './components/skeleton/dashboard_skeleton'
import NonBiddingKeywordsSkeleton from './components/skeleton/non_bidding_keywords_skeleton'
import CanvasJSReact from '@canvasjs/react-charts'
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Freeze, Sort, Filter } from '@syncfusion/ej2-react-grids'
import moment from 'moment-timezone'
import SearchImpressionShareReport from './components/search_impression_share_report'

moment.tz.setDefault("America/New_York")
var CanvasJSChart = CanvasJSReact.CanvasJSChart

const Dashboard = (props) => {

	let gridInstance;
	const { non_bidding_keywords, dashboard_stats, default_account, getDashboardData, getNonBiddingKeywords, getAccountData } = props
	const [isPageLoading, setIsPageLoading] = useState(true)
	const [isNonBiddingKeywordsLoading, setIsNonBiddingKeywordsLoading] = useState(true)
	const [page, setPage] = useState(1)
	const startDate = moment().subtract(30, 'days').format('MM/DD/YYYY')
	const endDate = moment().subtract(1, 'day').format('MM/DD/YYYY')
	const [totalRows, setTotalRows] = useState(0)
	const [sortBy, setSortBy] = useState(null)
	const [sortDirection, setSortDirection] = useState(null)
	const [gridKey, setGridKey] = useState(Date.now())
	const filterSettings: any = { 
		type: 'Menu',
		operators: {
			stringOperator: [
				{ value: 'startswith', text: 'Starts With' },
				{ value: 'endswith', text: 'Ends With' },
				{ value: 'equal', text: 'Equal' },
		 		{ value: 'notequal', text: 'Not Equal' },
		 		{ value: 'contains', text: 'Contains' },
		 		{ value: 'doesnotcontain', text: 'Does Not Contain' },
			],
			numberOperator: [
				{ value: 'equal', text: 'Equal' },
		 		{ value: 'notequal', text: 'Not Equal' },
		 		{ value: 'greaterthan', text: 'Greater Than' },
		 		{ value: 'greaterthanorequal', text: 'Greater Than Or Equal' },
		 		{ value: 'lessthan', text: 'Less Than' },
		 		{ value: 'lessthanorequal', text: 'Less Than Or Equal' },
			],
		}
	}
	const [filterColumns, setFilterColumns] = useState([])

	const fetchDashboardData = useCallback( () => {
		const account_id = default_account.id
		const from_date = moment(startDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
		const to_date = moment(endDate, 'MM/DD/YYYY').format('YYYY-MM-DD')

		// setIsPageLoading(true)
		
		getDashboardData({ account_id, from_date, to_date }).then(response => {
			setIsPageLoading(false)
		}).catch(err => setIsPageLoading(false))	
	}, [default_account, getDashboardData, startDate, endDate])

	const fetchNonBiddingKeywords =  useCallback(() => {
		const account_id = default_account.id
		const sort_by = sortBy
		const sort_direction = sortDirection
		const filters = filterColumns
		const from_date = moment(startDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
		const to_date = moment(endDate, 'MM/DD/YYYY').format('YYYY-MM-DD')

		// setIsNonBiddingKeywordsLoading(true)

		getNonBiddingKeywords({ page, account_id, sort_by, sort_direction, filters, from_date, to_date }).then(response => {
			setIsNonBiddingKeywordsLoading(false)

			if(response.data.success) {
				setTotalRows(response.data.result.pagination.total_count)
				setGridKey(Date.now())
			}
		}).catch(err => setIsNonBiddingKeywordsLoading(false))
	}, [getNonBiddingKeywords, page, sortBy, sortDirection, filterColumns, default_account, startDate, endDate])

	useEffect(() => {
		if(default_account) {
			fetchDashboardData()
		}
	}, [default_account, fetchDashboardData])

	useEffect(() => {
		if(default_account) {
			fetchNonBiddingKeywords()
		}
	}, [default_account, fetchNonBiddingKeywords])

	useEffect(() => {
		if(gridInstance) {
			gridInstance.dataSource = non_bidding_keywords
		}
	}, [gridInstance, non_bidding_keywords, gridKey])

	const getAdSpendChartOptions = () => {
		const bidding_keywords_spend = dashboard_stats['bidding_keywords_spend']
		const wasted_keywords_spend = dashboard_stats['wasted_keywords_spend']

		return {

			animationEnabled: false,
			exportEnabled: false,
			title:{
				text: "Ad Spend"
			},
			toolTip: {
				contentFormatter: function (e){
					let total = e.entries[0].dataSeries.dataPoints.map(item => item.y).reduce((partialSum, a) => partialSum + a, 0)
					let ratio = 0

					if(total > 0) {
						ratio = Number(e.entries[0].dataPoint.y/total).toLocaleString('en-US', {style: 'percent', 'minimumFractionDigits': 2})
					}

					return `<b>${e.entries[0].dataPoint.label}: ${Number(e.entries[0].dataPoint.y).toLocaleString('en-US', {style: 'currency', currency: default_account.currency_code})}</b> (${ratio})`
				}
			},
			data: [{
				type: "pie",
				indexLabelFormatter: function (e) {
					return `${e.dataPoint.label}: ${Number(e.dataPoint.y).toLocaleString('en-US', {style: 'currency', currency: default_account.currency_code})}`
				},
				dataPoints: [
					{ y: bidding_keywords_spend, label: "Keywords Spend Bidding On"},
					{ y: wasted_keywords_spend, label: "Potentially Wasted Ad Spend"},
				],
			}]
		}
	}

	const getClicksChartOptions = () => {
		const bidding_keywords_clicks = dashboard_stats['bidding_keywords_clicks']
		const wasted_keywords_clicks = dashboard_stats['wasted_keywords_clicks']

		return {

			animationEnabled: false,
			exportEnabled: false,
			title:{
				text: "Keyword Clicks"
			},
			data: [{
				type: "pie",
				toolTipContent: "<b>{label}</b>: {y} (#percent%)",
				indexLabel: "{label}: {y} Clicks",
				dataPoints: [
					{ y: bidding_keywords_clicks, label: "Your Keywords"},
					{ y: wasted_keywords_clicks, label: "Other Keywords"},
				],
			}]
		}
	}

	const dataStateChange = (args) => {
		if(args.action.requestType !== 'stringfilterrequest') {
			if(gridInstance) {
				gridInstance.showSpinner()
			}
		}
		if(args.action.requestType === 'sorting') {
			if(args.action.columnName && args.action.direction) {
				setSortBy(args.action.columnName)
				setSortDirection(args.action.direction)
			} else {
				setSortBy(null)
				setSortDirection(null)
			}

			setPage(1)

			return
		}

		if(args.action.requestType === 'filtering') {

			if(args.action.action === 'filter') {
				let updatedFilters = filterColumns.map(item => {
					
					if(item.field === args.action.currentFilterObject.field) {
						return { ...item, field: args.action.currentFilterObject.field, operator: args.action.currentFilterObject.operator, value: args.action.currentFilterObject.value }
					}

					return item
				})

				let filteredFilterColumns = updatedFilters.filter(item => item.field === args.action.currentFilterObject.field)
				if(filteredFilterColumns.length === 0) {
					updatedFilters.push({field: args.action.currentFilterObject.field, operator: args.action.currentFilterObject.operator, value: args.action.currentFilterObject.value})
				}

				if(updatedFilters.length > 0) {
					setFilterColumns(updatedFilters)
				} else {
					setFilterColumns(result => [...result, {field: args.action.currentFilterObject.field, operator: args.action.currentFilterObject.operator, value: args.action.currentFilterObject.value}])
				}
			}

			if(args.action.action === 'clearFilter') {
				const indexToRemove = filterColumns.findIndex(item => item.field === args.action.currentFilterObject.properties.field)
				let updatedFilters = filterColumns.filter((item, index) => index !== indexToRemove)
				setFilterColumns(updatedFilters)
			}

			setPage(1)

			return
		}
	}

	const showClicks = (item) => Number(item.clicks).toLocaleString('en-US')
	const showImpressions = (item) => Number(item.impressions).toLocaleString('en-US')
	const showSpend = (item) => Number(item.spend).toLocaleString('en-US', {style: 'currency', currency: default_account.currency_code})
	/*const showClickShare = (item) => {
		if(item.search_click_share === 9.99) {
			// return '< 10%'
			return Number(item.search_click_share/100).toLocaleString('en-US', {'style': 'percent', 'minimumFractionDigits': 2})
		}

		return Number(item.search_click_share/100).toLocaleString('en-US', {'style': 'percent', 'minimumFractionDigits': 2})
	}*/

	const fetchAccountData = useCallback((props) => {
		return getAccountData(props)
	}, [getAccountData])

	return (
		<>
			<Layout>
				{default_account &&
					<>
						<Row>
							<Col md={12}>
								<Card>
									<Card.Header>Dashboard (Last 30 Days: {startDate} - {endDate})</Card.Header>

									<Card.Body>
										{!isPageLoading &&
										<>
											<div className="row">

						                        <div className="col-xl-3 col-md-6 mb-4">
						                            <div className="card border-left-primary shadow h-100 py-2">
						                                <div className="card-body">
						                                    <div className="row no-gutters align-items-center">
						                                        <div className="col mr-2">
						                                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
						                                                Clicks</div>
						                                            <div className="h5 mb-0 font-weight-bold text-gray-800">{Number(dashboard_stats.total_clicks).toLocaleString('en-US')}</div>
						                                        </div>
						                                        <div className="col-auto">
						                                            <i className="fas fa-calendar fa-2x text-gray-300"></i>
						                                        </div>
						                                    </div>
						                                </div>
						                            </div>
						                        </div>

						                        <div className="col-xl-3 col-md-6 mb-4">
						                            <div className="card border-left-success shadow h-100 py-2">
						                                <div className="card-body">
						                                    <div className="row no-gutters align-items-center">
						                                        <div className="col mr-2">
						                                            <div className="text-xs font-weight-bold text-success text-uppercase mb-1">Spend</div>
						                                            <div className="h5 mb-0 font-weight-bold text-gray-800">{Number(dashboard_stats.total_spend).toLocaleString('en-US', {style: 'currency', currency: default_account.currency_code})}</div>
						                                        </div>
						                                        <div className="col-auto">
						                                            <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
						                                        </div>
						                                    </div>
						                                </div>
						                            </div>
						                        </div>

						                        <div className="col-xl-3 col-md-6 mb-4">
						                            <div className="card border-left-info shadow h-100 py-2">
						                                <div className="card-body">
						                                    <div className="row no-gutters align-items-center">
						                                        <div className="col mr-2">
						                                            <div className="text-xs font-weight-bold text-info text-uppercase mb-1">CPC</div>
						                                            <div className="h5 mb-0 font-weight-bold text-gray-800">{Number(dashboard_stats.total_cpc).toLocaleString('en-US', {style: 'currency', currency: default_account.currency_code})}</div>
						                                        </div>
						                                        <div className="col-auto">
						                                            <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
						                                        </div>
						                                    </div>
						                                </div>
						                            </div>
						                        </div>

						                        <div className="col-xl-3 col-md-6 mb-4">
						                            <div className="card border-left-warning shadow h-100 py-2">
						                                <div className="card-body">
						                                    <div className="row no-gutters align-items-center">
						                                        <div className="col mr-2">
						                                            <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">Conversions</div>
						                                            <div className="h5 mb-0 font-weight-bold text-gray-800">{Number(dashboard_stats.total_conversions).toLocaleString('en-US')}</div>
						                                        </div>
						                                        <div className="col-auto">
						                                            <i className="fas fa-comments fa-2x text-gray-300"></i>
						                                        </div>
						                                    </div>
						                                </div>
						                            </div>
						                        </div>
						                    </div>

											<Row className="mb-4">
												<Col md={6}>
													<CanvasJSChart options = { getAdSpendChartOptions() } />
												</Col>

												<Col md={6}>
													<CanvasJSChart options = { getClicksChartOptions() } />
												</Col>
											</Row>
										</>
										}
										{isPageLoading &&
											<DashboardSkeleton />	
										}


										{!isNonBiddingKeywordsLoading &&	
										<>
											<Row className="mb-4">
												<Col md={12}>
													<h4>Non Bidding Keywords</h4>
													<GridComponent height={500} ref={grid => gridInstance = grid}  allowFiltering={true} allowSorting={true} frozenColumns={1} loadingIndicator={{ indicatorType: 'Shimmer' }} filterSettings={filterSettings} dataStateChange={dataStateChange}>
														<ColumnsDirective>
															<ColumnDirective field='search_term' width='300' headerText='Search Term' />
											            	<ColumnDirective field='search_term_match_type' width='220' headerText='Search Term Match Type' />
											            	<ColumnDirective field='clicks' width='200' headerText='Clicks' template={showClicks} />
											            	<ColumnDirective field='impressions' width='200' headerText='Impressions' template={showImpressions} />
											            	<ColumnDirective field='spend' width='200' headerText='Spend' template={showSpend} />
														</ColumnsDirective>
														<Inject services={[Freeze, Sort, Filter]}/>
													</GridComponent>
												</Col>
											</Row>
											<Row>
												<Col md={12}>
													<div className="text-center">
														<PaginationControl page={page} total={totalRows} changePage={(page) => {setPage(page); gridInstance.showSpinner();}} limit={100} ellipsis={1} />
													</div>
												</Col>
											</Row>
										</>
										}
										{isNonBiddingKeywordsLoading &&
											<NonBiddingKeywordsSkeleton />
										}

										<SearchImpressionShareReport account={default_account} startDate={startDate} endDate={endDate} fetchAccountData={fetchAccountData} />
										
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</>
				}
			</Layout>
		</>
	)
}

export default memo(Dashboard)